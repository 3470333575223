.contentWrapper{
 font-family: 'DM Sans';
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
 gap:10px;
 font-weight: 500;
 color:white;
}
.card{
    background-color: rgba(24, 24, 26, 0.4);
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 270px;
  height: 300px;
  backdrop-filter: blur(30px);
  border-radius: 12px;
  font-family: 'DM Sans', sans-serif;
  border: 1px solid rgb(78, 78, 82);
  color:white;
  padding: 20px;
  margin-top: 10px;
}

.chatName{
  font-size: 24px;
}
.chatLogo{
  height: 60px;
}

.iconTextLockup{
  display: flex;
  color: linear-gradient(50deg,#3748e4 25.61%,#37b0e4 89.23%);
  gap: 6px;
  cursor: pointer;
  margin-top: 10px;
  align-items: center;
}

.btnLink{
  color: #5966db;
  font-weight: 700;
}

.btnLink:hover{
  filter: brightness(1.2);
  text-decoration: underline;
}

.chatURL{
    font-size: 12px;
    font-weight: 700;
    opacity: .8;
    margin-top: 4px;
}


.viewConversationBtn{
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: linear-gradient(50deg,#3748e4 25.61%,#37b0e4 89.23%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
}