.contentWrapper{
    display: grid;
    grid-template-columns: 300px 1fr;
    font-family: 'DM Sans', sans-serif;
}

.leftRail{
    height: 100vh;
    padding: 16px;
    background-color: red;
    backdrop-filter: blur(30px);
    background-color: rgba(24, 24, 26, 0.4);
    border: 1px solid rgb(78, 78, 82);
}

.leftRailHeading{
    color: white;
    font-size: 20px;
    font-weight: 500;
    
}

.return:hover{
   text-decoration: underline;
}

.converationItem{
    position: relative;
    display: block;
    cursor: pointer;
    background-color: rgba(35, 35, 35, 0.9);
    color:white;
    height: 66px;
    margin-top: 8px;
    border-radius: 10px;

}

.converationItem:hover{
    background-color: rgba(62, 62, 62, 0.9);
}
.initMsg{
    position: relative;
    display: block;
    cursor: pointer;
    top: 36px;
    margin-top: 20px;
    text-overflow: ellipsis;
    text-align: left;
    white-space:nowrap;
    overflow:hidden;
    width: 230px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.date{
    position: absolute;
    top: 10px;
    font-weight: 700;
    left: 10px;
    opacity: .7;
}
.time{
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 700;
    opacity: .7;
}



.feedContainer{
    padding: 20px;
}

.chatCanvas{

}