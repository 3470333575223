/* MessageBubble.module.css */
.user {
  display: block;
  border-radius: 12px;
  margin-bottom: 24px;
  color: white;
  text-align: left;
  padding-left: 24px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  max-width: 90%;
  font-family: 'DM Sans', sans-serif;
  background-color: rgba(24, 24, 26, 0.4);
  backdrop-filter: blur(30px);
  border-radius: 12px;
  font-family: 'DM Sans', sans-serif;
  border: 1px solid rgb(78, 78, 82);
  color: white;
  padding: 20px;
  width: fit-content;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;

}

.bot {
  display: block;
  border-radius: 12px;
  margin-bottom: 24px;
  width: fit-content;
  color: white;
  text-align: left;
  padding-left: 24px;
  max-width: 90%;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: 'DM Sans', sans-serif;
  background-color: rgba(24, 24, 26, 0.4);
  backdrop-filter: blur(30px);
  border-radius: 12px;
  font-family: 'DM Sans', sans-serif;
  border: 1px solid rgb(78, 78, 82);
  color: white;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;

}

.chatLogo {
  position: relative;
  top: 20px;
  left: 10px;
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 10px;
  background-color: white;
  border-radius: 100px;  

}

.text {
  font-size: 14px;
  color: white;
  line-height: 1.6;
  margin-bottom: 12px;
}

.role {
  font-size: 14px;
  color: #666;
}

.heading {
  font-size: 24px;
  color: white;
  font-weight: 500;

}

.subheading {
  font-size: 16px;
  opacity: 1;
  font-weight: 600;
}

.link {

  text-decoration: underline;
}

.editBtn {
  position: absolute;
  display: grid;
  place-content: center;
  padding: 8px;
  border-radius: 10px;
  top: 16px;
  right: 16px;
  background-color: var(--brand-color);
  font-family: 700;
  opacity: .8;
}