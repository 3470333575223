@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Krona+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
:root {
  --primary-color: #388BFD;
  --success-color: #57C15B;
  --secondary-color: #eff1f3;
  --primary-white: #f4f4f4;
  --lottie-bg: #f4f4f4;
  --dark-009: rgb(90, 90, 90);
  --dark-008: rgb(80, 80, 80);
  --dark-007: rgb(70, 70, 70);
  --dark-006: rgb(60, 60, 60);
  --dark-005: rgb(50, 50, 50);
  --dark-004: rgb(40, 40, 40);
  --dark-003: rgb(30, 30, 30);
  --dark-002: rgb(20, 20, 20);
  --dark-001: rgb(10, 10, 10);
  --dark-000: rgb(0, 0, 0);
  --font-size: 16px;
}

html{
  /* background-color: #171717;; */
}

.App {
  text-align: left;
  height:100vh;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 12px;
  width:100vw;
  overflow: hidden;
  background-color: transparent;
}

body{
  content-visibility: auto;
  background-color: hsla(0, 0%, 0%, 1);
  background-image:
    radial-gradient(at 72% 53%, hsla(26, 0%, 7%, 1) 0px, transparent 50%),
    radial-gradient(at 100% 0%, hsla(240, 80%, 40%, 0.45) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(0, 0%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 66% 58%, hsla(337, 0%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(167, 100%, 69%, 0.4) 0px, transparent 50%),
    radial-gradient(at 98% 97%, hsla(240, 100%, 70%, 0.63) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(310, 0%, 9%, 0.27) 0px, transparent 50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}



.primary-btn{
  padding-left: 12px;;
  padding-right: 12px;
  display: grid;
  place-content: center;
  width: fit-content;
  color:white;
  background: linear-gradient(50deg,#3748e4 25.61%,#37b0e4 89.23%);
  outline: none;
  border: none;
  border-radius: 10px;;
  font-family: 'DM Sans';
  height: 32px;
  font-weight: 600;
  cursor: pointer;
}

.primary-btn:hover{
  filter: brightness(1.1)
}


.secondary-btn{
  display: grid;
  place-content: center;
  width: fit-content;
  padding-left: 12px;;
  padding-right: 12px;
  color:var(--primary-color);
  border: 2px solid #3748e4;
  outline: none;
  border-radius: 10px;
  background-color: transparent;
  height: 32px;
  font-size: 12px;
  font-family: 'DM Sans';
  font-weight: 800;
}

.primary-btn:disabled{
  color: #ccc;
  background-color: #666666;
}

.input-subtext{
  margin-top: 2px;
  margin-left: 4px;
}
